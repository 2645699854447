<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if='!loading && item'>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <h3 class='justify-center text-center my-3 mx-1 font-weight-bold headline text-break' style='color: #3F51B5'>
                {{ item.SchoolName }} <br> {{ item.CourseName }} <br> BİREYSEL DEĞERLENDİRME KARNESİ
              </h3>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class='justify-center text-center text-break py-2'>
                {{ item.StudentFullName }} {{ item.StudentClassBranch }}
              </v-card-title>
              <v-card-title class='justify-center text-center text-break py-2 '>
                {{ item.FormName }}
              </v-card-title>
              <v-card-title class='justify-center text-center py-2'>
                {{ item.FormDate | moment }}
              </v-card-title>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <apex-chart class="my-3" type='bar' height='350' :options='chartOptions' :series='series'></apex-chart>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment/moment'

export default {
  name: 'form-user-report',
  data() {
    return {
      formId: this.$route.params.id,
      rows: [],
      loading: true,
      pointTemplates: [],
      rateWatcher: false,
      item: {},
      series: [{
        name: 'PUAN',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 300
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
            endingShape: 'rounded',
            borderRadius: 3,
            distributed: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        xaxis: {
          categories: ['', '', '', '']
        },
        yaxis: {
          max: 100,
          tickAmount: 5,
          show: false,
        },
        fill: {
          opacity: 1
        },
        toolbar: {
          show: false
        },
        legend: {
          show: false
        },
        grid: {
          show: true
        }
      }
    }
  },
  watch: {
    rateWatcher(val) {
      if (val) this.rater(this.rows, this.pointTemplates)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('DD.MM.YYYY') : ''
    }
  },
  mounted() {
    this.getDataFromApi()
    this.getFormDetails()
    this.getPointTemplates()
  },
  methods: {
    rater(rows, pointTemplates) {
      //rate finder method start
      let pointTemplate =
          rows[0].FormUser.Form.PointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.PointTemplateId)
              : null
      if (pointTemplate) {
        pointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      let advancedPointTemplate =
          rows[0].FormUser.Form.AdvancedPointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.AdvancedPointTemplateId)
              : null
      if (advancedPointTemplate) {
        advancedPointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      rows.map((s) => {
        let rate = (s.Point / s.MaxPoint) * 100
        if (pointTemplate) {
          pointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.PointTemplate = d.Name
          })
        }
        if (advancedPointTemplate) {
          advancedPointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.AdvancedPointTemplate = d.Name
          })
        }
      })

      // this.chartOptions.xaxis.categories = this.rows.map((d) => d.AssessmentArea.Name + ' - ' + d.AdvancedPointTemplate)
      this.chartOptions.xaxis.categories = this.rows.map((d) => d.AssessmentArea.Name != null ? d.AssessmentArea.Name : '')

      for (let i = 0; i < this.chartOptions.xaxis.categories.length; i++) {
        this.chartOptions.colors[i] = this.rows.find(s => this.chartOptions.xaxis.categories[i] == s.AssessmentArea.Name).AssessmentArea.Color
      }

      this.loading = false
      //rate finder method end
    },

    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/FormUserAssessmentArea', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        Query: {
          FormId: this.formId
        }
      })
          .then((data) => {
            this.rows = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;

            this.series = [{
              name: 'PUAN',
              data: this.rows.map((d) => d.Point)
            }]
          })
          .catch(({response}) => {
            this.loading = false
            ApiService.showError(response)
          })
    },

    getPointTemplates() {
      ApiService.setHeader()
      ApiService.post('api/PointTemplate', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.pointTemplates = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getFormDetails() {
      ApiService.setHeader()
      ApiService.get('api/Form/FormUserReport/' + 0 + '/' + this.formId)
          .then((data) => {
            this.item = data.data
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
